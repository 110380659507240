import React, { useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { s3 } from './awsConfig'; // Import your AWS configuration

const formattedTimestamp = new Date().toISOString().slice(0, 19).replace("T", " ");

const YourComponent = () => {
  const [formData, setFormData] = useState({
    comment: '',
    fileUrl: '',
    timestamp: formattedTimestamp,
  });

  const storedDataUserId = JSON.parse(localStorage.getItem('emailId'));
  const storedDataEmail = JSON.parse(localStorage.getItem('emailData'));

  const onDrop = async (acceptedFiles) => {
    try {
      const file = acceptedFiles[0];

      if (file) {
        const fileName = `images/${Date.now()}_${file.name}`;
        const params = {
          Bucket: 'naonelbucket',
          Key: fileName,
          Body: file,
          ACL: 'public-read',
        };

        const response = await s3.upload(params).promise();
        console.log('File uploaded successfully:', response.Location);

        setFormData({
          ...formData,
          fileUrl: response.Location,
        });
      }
    } catch (error) {
      console.error('Error uploading files:', error);
    }
  };
  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
      userid: storedDataUserId,
      username: storedDataEmail,
      timestamp: formattedTimestamp,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('https://juxzvtxnsi.execute-api.us-east-1.amazonaws.com/createPostTimeline', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();
      console.log(data, formData);

      // Resetting the form fields
      setFormData({
        comment: '',
        fileUrl: '',
        timestamp: formattedTimestamp,
      });

      // Trigger custom event to notify about the submission
      document.dispatchEvent(new Event('formSubmitted'));

    } catch (error) {
      console.error('Error posting data:', error);
    }
  };

  return (
    <div className="newsfeed">
      <form onSubmit={handleSubmit}>
        <label>
          <input
            type="text"
            name="comment"
            placeholder="Share knowledge..."
            className="post"
            value={formData.comment}
            onChange={handleInputChange}
          />
        </label>
        <br />
        <div {...getRootProps()} style={dropzoneStyle}>
          <input {...getInputProps()} className="drop"/>
          {formData.fileUrl ? (
            <img src={formData.fileUrl} alt="Uploaded" style={{ maxWidth: '100%', maxHeight: '200px' }} />
          ) : isDragActive ? (
            <p>Drop the files here ...</p>
          ) : (
            <p>...</p>
          )}
        </div>
        <button type="submit">Submit</button>
      </form>
    </div>
  );
};

const dropzoneStyle = {
  border: '2px dashed #cccccc',
  borderRadius: '15px',
  padding: '20px',
  textAlign: 'center',
  cursor: 'pointer',
  margin: '20px 0'
};

export default YourComponent;
