import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Status from './Status';
import MenuLogin from './MenuLogin';
import logo from '../img/naonel_app.png';

const Header = () => {
  const location = useLocation();

  const PAGE_TITLES = {
    "/proyects": "All my proyects"
  };

  const { pathname } = useLocation();

  useEffect(() => {
    console.log("Location Pathname:", location.pathname);
  }, [location]);

   return (
     <header>
       <section className="logo">
         <a href="/dashboard">
           <img src={logo} alt="Description of the image" />
         </a>
         <span>
              {PAGE_TITLES[pathname]}
          </span> {/* Display page title next to the logo */}
       </section>
       <section className="menu">
         <MenuLogin />
         <Status />
       </section>
     </header>
   );
 };

export { Header };
