import React, { useState, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { AccountContext } from './Account'; // Import AccountContext from the correct file
import { Header } from './Header';
import { HomeBanners } from './HomeBanners';
import { Link } from 'react-router-dom';
import { ReactComponent as SubmitIcon } from '../img/icon_chevron-right_.svg';

const Signup = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();
    const { createAccount } = useContext(AccountContext);

    const onSubmit = (event) => {
        event.preventDefault();

        createAccount(email, password)
        .then(data => {
            console.log("Siggned up!", data);
            navigate('/confirmation'); // Move this line inside the authenticate promise resolution
            localStorage.setItem('emailConfirmation', email);
        })
        .catch(err => {
            console.log("Not Siggned up!", err);
            alert(err);
            if (err == "UsernameExistsException: An account with the given email already exists."){
                navigate('/login');
            }
        });
    };

    return (

        <div>
          <Header/>
            <div class="portada-home">
              <header>
                <h1>Most powerful tool for your bussiness run</h1>
                <h3>manage your mails, chats, proyects & performance of your team</h3>
              </header>
              <section>
                <span>Create your account</span><br />
                  <form onSubmit={onSubmit}>
                      <input
                          value={email}
                          placeholder="Email"
                          onChange={(event) => setEmail(event.target.value)}
                      /><br />

                      <input
                          value={password}
                          type="password"
                          placeholder="Password"
                          onChange={(event) => setPassword(event.target.value)}
                      /><br />
                      <aside>
                        <Link to="/login"><small>Already a user?</small></Link>
                        <button type="submit">
                          <SubmitIcon style={{ height: '15px', width: '15px' }}/>
                        </button>
                      </aside>
                  </form>
                </section>
            </div>
          <HomeBanners/>
        </div>

    );
};

export default Signup;
