import React, { useState, useContext, useEffect } from 'react';
import { AccountContext } from './Account';
import { useNavigate } from 'react-router-dom';

const Status = () => {
  const [status, setStatus] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const { getSession, logout } = useContext(AccountContext);

  useEffect(() => {
    // Function to fetch the user's session status
    const fetchSession = async () => {
      try {
        const session = await getSession();
        console.log('Session:', session);
        setStatus(true); // User is authenticated
      } catch (error) {
        setStatus(false); // User is not authenticated
        setError(error);
        navigate('/');
      }
    };

    fetchSession(); // Fetch the session status when the component mounts

    // Check if the component re-renders after logout
    console.log('Component re-rendered after logout');
  }, [getSession]);

  const handleLogout = async () => {
    await logout();
    setStatus(false); // User is logged out
    navigate('/');
  };

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div style={{ fontSize: "24px" }}>
      {status ? (
        <>
          <button onClick={handleLogout}>Logout</button>
        </>
      ) : (
        <>
          <p>Please login</p>
          {/* Render your login button here */}
        </>
      )}
    </div>
  );
};

export default Status;
