// awsConfig.js
import AWS from 'aws-sdk';

AWS.config.update({
  accessKeyId: 'AKIAWAP5ISNGDNUUST22',
  secretAccessKey: 'R4MH50h+CiBtcxjFrKYzqfyV4IyH8x5bWH2kVYLD',
  region: 'us-east-1', // Replace with your AWS region
});

export const s3 = new AWS.S3();
