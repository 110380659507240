import React, { createContext } from 'react';
import analytics1 from '../img/analytics1.png';
import analytics2 from '../img/analytics2.png';

const DashboardAnalytics = (props) => {



  return (
    <div class="analytics">
      <div>
        <h3>Sprint</h3>
        <img src={analytics1} alt="Description of the image" />
      </div>
      <div>
        <h3>Overall advance</h3>
        <img src={analytics2} alt="Description of the image" />
      </div>
      <div>
        <h3>Assigments by proyect</h3>
        <img src={analytics1} alt="Description of the image" />
      </div>
    </div>

  )
};

export { DashboardAnalytics };
