import React, { useState } from 'react';
import UserPool from "../UserPool";
import { CognitoUserAttribute, CognitoUser } from 'amazon-cognito-identity-js';
import { useNavigate } from 'react-router-dom';
import Status from '../components/Status';

import NewsFeed from '../components/NewsFeed';
import NewsFeedPost from '../components/NewsFeedPost';
import { Header } from '../components/HeaderLogin';
import { Footer } from '../components/FooterLogin';

import { DashboardAnalytics } from '../components/dashboardAnalytics';
import { Chat } from '../components/chat';

const Dashboar = () => {

    return (
        <div class="app">
            <Header/>
            <div class="dashboard">
              <main>
                <section><DashboardAnalytics /></section>
                <section>
                  <NewsFeedPost />
                  <NewsFeed />
                </section>
                <section><Chat /></section>
              </main>
            </div>
            <Footer/>
        </div>
    );
};

export default Dashboar;
