import React, { useState, useContext } from 'react';
import { AccountContext } from './Account'; // Import AccountContext from the correct file
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Header } from './Header';
import { HomeBanners } from './HomeBanners';
import { ReactComponent as SubmitIcon } from '../img/icon_chevron-right_.svg';

const Login = () => {
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();


    const { authenticate } = useContext(AccountContext);

    const onSubmit = (event) => {
        event.preventDefault();

        authenticate(email, password)
            .then(data => {
                console.log("Logged in!", data);
                navigate('/dashboard'); // Move this line inside the authenticate promise resolution
            })
            .catch(err => {
                console.log("Not logged in!", err);
                alert(err);
                if (err == "UserNotConfirmedException: User is not confirmed."){
                    navigate('/confirmation');
                }
            });
    };

    return (
        <div>
        <Header/>
          <div class="portada-home">
            <header>
              <h1>Most powerful tool for your bussiness run</h1>
              <h3>manage your mails, chats, proyects & performance of your team</h3>
            </header>
            <section>
                <span>Login on your account</span><br />
                <form onSubmit={onSubmit}>
                    <input
                        value={email}
                        placeholder="Email"
                        onChange={(event) => setEmail(event.target.value)}
                    /><br />

                    <input
                        value={password}
                        type="password"
                        placeholder="Password"
                        onChange={(event) => setPassword(event.target.value)}
                    /><br />
                    <aside>
                        <Link to="/"><small>Create account</small></Link>
                        <button type="submit">
                            <SubmitIcon style={{ height: '15px', width: '15px' }}/>
                        </button>
                    </aside>
                </form>
              </section>
          </div>
          <HomeBanners/>
        </div>
    );
};

export default Login;
