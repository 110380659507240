import React, { useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';

import UserPool from "../UserPool";
import { CognitoUserAttribute, CognitoUser } from 'amazon-cognito-identity-js';
import { useNavigate } from 'react-router-dom';
import ItemList from '../components/ItemList';

import { Header } from '../components/HeaderLogin';
import { Footer } from '../components/FooterLogin';

const Dashboar = () => {

    return (
          <div className="app">
            <Header/>            
              <ItemList />
            <Footer/>
          </div>
    );
};

export default Dashboar;
