import React, { createContext } from 'react';
import chatfull from '../img/chat.png';
import chaticon from '../img/chatBurbuja.png';

const Chat = (props) => {



  return (
    <div class="chat">
      <section>
        <img src={chatfull} alt="Description of the image" />
      </section>
      <section>
        <img src={chaticon} alt="Description of the image" />
      </section>
    </div>

  )
};

export { Chat };
