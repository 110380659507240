import React, { createContext } from 'react';
import support from '../img/bx_support.png';
import downloadapp from '../img/carbon_intent-request-upgrade.png';
import faqs from '../img/streamline_interface-help-question-circle-circle-faq-frame-help-info-mark-more-query-question.png';
import nightmode from '../img/material-symbols_mode-night.png';

const Footer = (props) => {



  return (
    <div class="footer">
      <div>
        <img src={nightmode} alt="Description of the image" />
      </div>
      <div>
        <img src={faqs} alt="Description of the image" />
      </div>
      <div>
        <img src={downloadapp} alt="Description of the image" />
      </div>
      <div>
        <img src={support} alt="Description of the image" />
      </div>
      <div>
        <span>
          <a href="/dashboard">naonel.com</a>
        </span>
      </div>
    </div>

  )
};

export { Footer };
