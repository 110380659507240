import React, { useState, useContext } from 'react';
import { AccountContext } from './Account';
import { CognitoUser, AuthenticationDetails } from 'amazon-cognito-identity-js';
import UserPool from '../UserPool';
import { useNavigate } from 'react-router-dom';

const Confirmation = () => {
    const [confirmationCode, setConfirmationCode] = useState("");
    const [isCodeResent, setCodeResent] = useState(false);
    const navigate = useNavigate();
    const emailfor = localStorage.getItem('emailConfirmation');
    const email = emailfor;  // Capture the email before using it asynchronously

    const { confimation, resendConfirmationCode } = useContext(AccountContext);

    const handleConfirmation = async (event) => {
        event.preventDefault();
    
    
        confimation(email, confirmationCode)
            .then(data => {
                console.log("Confirmation granted!", data);
                navigate('/login'); // Move this line inside the authenticate promise resolution
            })
            .catch(err => {
                console.log("Not granted confirmation!", err);
                alert(err);
            });
        
    };

    const handleResendCode = async () => {
        // Assume resendConfirmationCode is a function that sends a new confirmation code to the user's email
        resendConfirmationCode(email)
            .then(() => {
                console.log("New confirmation code sent!");
                setCodeResent(true);
            })
            .catch(err => {
                console.log("Failed to resend confirmation code!", err);
                alert(err);
            });
    };

    

    return (
        <div>
            <h2>Confirmation</h2>
            <form onSubmit={handleConfirmation}>
                <label htmlFor="confirmationCode">Confirmation Code</label><br />
                <input
                    value={confirmationCode}
                    onChange={(event) => setConfirmationCode(event.target.value)}
                /><br />
                <button type="submit">Confirm</button>
                {!isCodeResent && (
                    <button type="button" onClick={handleResendCode}>
                        Resend Confirmation Code
                    </button>
                )}
                {isCodeResent && <p>New confirmation code has been sent. Check your email.</p>}
            </form>
        </div>
    );
};

export default Confirmation;
