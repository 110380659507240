import React, { createContext } from 'react';
import logo from '../img/naonel_app.png';
import banner1 from '../img/home-banner1_desk.png';
import banner2 from '../img/home-banner2_desk.png';
import banner3 from '../img/home-banner3_desk.png';

const HomeBanners = (props) => {



  return (

    <div>
      <div class="bannerGrey">
        <h2>One app for a successfull business</h2>
      </div>
      <div class="bannerWhite">
        <img src={banner1} alt="Description of the image" />
      </div>
      <div class="bannerBlue">
        <img src={banner2} alt="Description of the image" />
      </div>
      <div class="bannerWhite">
        <img src={banner3} alt="Description of the image" />
      </div>
      <div class="bannerGrey">
        <h2>Check our free demo by login</h2>
      </div>
      <header>
        <img src={logo} alt="Description of the image" />
      </header>
    </div>
  )
};

export { HomeBanners };
