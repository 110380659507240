import React, { createContext } from 'react';
import myImage from '../img/naonel_app.png';

const Header = (props) => {



  return (
    <header>
      <img src={myImage} alt="Description of the image" />
    </header>

  )
};

export { Header };
