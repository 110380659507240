import React, { useState, useEffect } from 'react';

function NewsFeed() {
  const [records, setRecords] = useState([]);
  const [visibleRecords, setVisibleRecords] = useState([]); // Add this line
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const pageSize = 10;
  const refreshInterval = 5000; // 5 seconds

  const fetchData = async (page) => {
    try {
      setIsLoading(true);

      const response = await fetch(`https://juxzvtxnsi.execute-api.us-east-1.amazonaws.com/getTimeline?page=${page}&pageSize=${pageSize}&orderBy=timestamp_desc`);
      const jsonData = await response.json();

      if (Array.isArray(jsonData.result)) {
        return jsonData.result.sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp));
      } else if (jsonData.message) {
        console.error('Error message from server:', jsonData.message);
        return [];
      } else {
        console.error('Invalid data format:', jsonData);
        return [];
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      return [];
    } finally {
      setIsLoading(false);
    }
  };

  const handleReadMore = async () => {
    const nextPage = currentPage + 1;
    const newRecords = await fetchData(nextPage);
    setRecords((prevRecords) => [...prevRecords, ...newRecords]);
    setCurrentPage(nextPage);
  };

  const refreshData = async () => {
    try {
      console.log('Refreshing data...');
      const newRecords = await fetchData(currentPage);
      console.log('Fetched data for refresh:', newRecords);

      setRecords(newRecords);
      // Update visibleRecords based on records and currentPage
      setVisibleRecords(newRecords.slice(0, currentPage * pageSize));
    } catch (error) {
      console.error('Error refreshing data:', error);
    }
  };

  useEffect(() => {
    const fetchDataAndRefresh = async () => {
      try {
        console.log('Fetching data...');
        const data = await fetchData(currentPage);
        console.log('Fetched data:', data);
        setRecords(data);
        // Update visibleRecords based on records and currentPage
        setVisibleRecords(data.slice(0, currentPage * pageSize));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchDataAndRefresh();
  }, [currentPage, pageSize]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      refreshData();
      console.log('Data refreshed:', records);
    }, refreshInterval);

    return () => {
      clearInterval(intervalId);
    };
  }, [currentPage, refreshInterval]);

  console.log('Component is rendering');
  console.log('Updated visibleRecords:', visibleRecords);

  return (
    <div class="newsfeedlist">
          {visibleRecords.map((list, index) => (
            <main key={index} id={`record-${index}`}>
              <div class="imagepost">
                <img
                  src={list[5]?.stringValue}
                  alt="Uploaded"
                />
              </div>
              <div class="text">{list[1]?.stringValue}</div>
              <div class="user">{list[3]?.stringValue && list[3]?.stringValue.substring(0, list[3]?.stringValue.indexOf('@'))}</div>
              <div class="time">{list[6]?.stringValue}</div>
            </main>
          ))}

      <button disabled={isLoading} onClick={handleReadMore}>
        {isLoading ? 'Loading...' : 'Read More'}
      </button>
    </div>
  );
}

export default NewsFeed;
