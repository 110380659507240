import React, { createContext } from 'react';
import { CognitoUser, AuthenticationDetails, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import Pool from "../UserPool";

const AccountContext = createContext();

const Account = (props) => {
  /** Get session **/
  const getSession = async () => {
    return await new Promise((resolve, reject) => {
      const user = Pool.getCurrentUser();
      if (user){
        user.getSession((err, session) => {
          if (err) {
            reject();
          } else {
            resolve(session);
          }
        })
      } else {
        reject();
      }
    })
  };

  /** Login **/
  const authenticate = async (Username, Password) => {
    try {
      const result = await authenticateUserAsync(Username, Password);
      return result;
    } catch (error) {
      throw error;
    }
  };

  const authenticateUserAsync = (Username, Password) => {
    return new Promise((resolve, reject) => {
      const user = new CognitoUser({ Username, Pool });
      const authDetails = new AuthenticationDetails({ Username, Password });

      user.authenticateUser(authDetails, {
        onSuccess: (data) => {
          console.log("onSuccess:", data);
          const idTokenPayload = data.getIdToken().payload;
          const email = idTokenPayload.email;
          const userid = idTokenPayload.sub; // Extracting user ID from the subject claim
          
          console.log('User ID:', userid);
          console.log('User Email:', email);

          try {
            localStorage.setItem('emailData', JSON.stringify(email));
            localStorage.setItem('emailId', JSON.stringify(userid));
          } catch (error) {
            console.error('Error storing in localStorage:', error);
          }

          resolve(data);
        },
        onFailure: (err) => {
          console.error("onFailure:", err);
          reject(err);
        },
        newPasswordRequired: (data) => {
          console.log("newPasswordRequired:", data);
          resolve(data);
        },
      });
    });
  };

  /** Logout **/
  const logout = () => {
    console.log("Logout function called");
    const user = Pool.getCurrentUser();
    if (user){
      console.log("Logging out user:", user.getUsername());
      user.signOut();
    }
  };

  /** Create Account **/
  const createAccount = async (email, password) => {
    try {
      // Create a new user in Cognito with the provided email and password
      const resultofcreateaccount = await createUserInCognito(email, password);
      // After successfully creating the account, you can optionally log the user in.
      // You can call your existing authenticate function here if needed.
      return resultofcreateaccount; // Return true to indicate successful account creation
    } catch (error) {
      throw error;
    }
  };

  const createUserInCognito = (email, password) => {
    return new Promise((resolve, reject) => {
      const attributeList = [
        new CognitoUserAttribute({
          Name: 'email',
          Value: email,
        }),
        // You can add more attributes here if needed
      ];

      Pool.signUp(email, password, attributeList, null, (err, data) => {
        if (err) {
          console.error('Error creating user:', err);
          reject(err);
        } else {
          console.log('User created successfully:', data);
          resolve(data);
        }
      });
    });
  };

  /** Confirmation process **/
  const confimation = async (email, confirmationCode) => {
    try {
      const result = await confirmUserAsync(email, confirmationCode);
      return result;
    } catch (error) {
      throw error;
    }
  };

  const confirmUserAsync = (email, confirmationCode) => {
    return new Promise((resolve, reject) => {
      const user = new CognitoUser({
        Username: email,
        Pool: Pool
      });
      
      const authDetails = new AuthenticationDetails({
        Username: email,
        Password: confirmationCode
      });

        user.confirmRegistration(confirmationCode, true, (err, result) => {
          if (err) {
              console.error('Confirmation error:', err);
              reject(err);
          } else {
              console.log('Confirmation successful', result);
              resolve(result);
          }
      });
    });
  };

/** Resend Confirmation Code **/
const resendConfirmationCode = async (email) => {
  const userData = {
    Username: email,
    Pool
  };

  const cognitoUser = new CognitoUser(userData);

  try {
    await cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        console.error('Error resending confirmation code:', err);
        throw err;
      }
      console.log('Confirmation code resent successfully:', result);
      return true;
    });
  } catch (error) {
    console.error('Error resending confirmation code:', error);
    throw error;
  }
};


  return (
    <AccountContext.Provider value={{ authenticate, getSession, logout, createAccount, confimation, resendConfirmationCode }}>
      {props.children}
    </AccountContext.Provider>  
  )
};

export { Account, AccountContext };
