import React, { useState, useContext } from 'react';
import { AccountContext } from './Account'; // Import AccountContext from the correct file
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

const MenuLogin = () => {


    return (
        <div class="menuin">
            <Link to="/proyects">Proyects</Link>
        </div>
    );
};

export default MenuLogin;
